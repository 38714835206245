import React from 'react';
import styled from 'styled-components';

// Estilos del contenedor del paso
const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  padding: 0px;
`;

// Estilos para el título
const Title = styled.h3`
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

// Estilos para la descripción
const Description = styled.p`
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #555;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

// Estilos del botón "Participar"
const NextButton = styled.button`
  width: 100%;
  max-width: 300px;
  padding: 15px;
  background-color: #FF5C37;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff7043;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 12px;
  }
`;

const Step1 = ({ onNext, currentStep }) => {

  // Función para manejar el envío
  const handleSubmit = () => {
    onNext(); // Avanza al siguiente paso cuando se hace clic en "Participar"
  };

  return (
<StepContainer>
  <Title><strong>Encuesta de Satisfacción</strong> Salón de Belleza Amapola</Title>
  <Description>
    <strong>Queremos brindarte la mejor experiencia posible.</strong> <strong>Tu opinión</strong> es muy <strong>valiosa</strong> para nosotros. 
    Al completar esta encuesta, <strong>participarás en un sorteo de S/.200 en consumos</strong> en nuestro salón. 
    <strong>¡Gracias por tu tiempo!</strong>
  </Description>

  {/* Botón de "Participar" */}
  <NextButton onClick={handleSubmit}><strong>Participar</strong></NextButton>
</StepContainer>
  );
};

export default Step1;