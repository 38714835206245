import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step6Music from './Step6Music';
import Step7 from './Step7';
import Step8 from './Step8';
import Step9 from './Step9';
import Step10 from './Step10';
import Step11 from './Step11';
import FinalStep from './FinalStep';
import Preloader from './Preloader';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  text-align: left;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
`;

const CloseButton = styled.button`
  background-color: #FF5C37;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  float: right;
`;

const LogoImage = styled.img`
  width: 250px;
  height: auto;
  margin-top: 20px;
  margin-bottom: 0px;
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #F6ECE7;
  padding: 20px;
  position: relative;
`;

const StepWrapper = styled.div`
  width: 22%;
  background: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 90%;
    padding: 20px;
  }
`;

const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
`;

const StepContent = styled.div`
  margin-bottom: 20px;
  animation: ${(props) => (props.isEntering ? slideIn : slideOut)} 0.5s ease both;
`;

const ProgressBarContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #e0e0e0;
  z-index: 9999;
`;

const ProgressBarFill = styled.div`
  height: 100%;
  background-color: #FF5C37;
  width: ${(props) => props.width}%;
  transition: width 0.4s ease;
`;

const MultiStepForm = () => {
  const [currentStep, setCurrentStep] = useState(1); // Step 1 es el paso de introducción
  const [isEntering, setIsEntering] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedVisitFrequency, setSelectedVisitFrequency] = useState('');
  const [recommendationStep3, setRecommendationStep3] = useState('');
  const [selectedStaff, setSelectedStaff] = useState('');
  const [selectedScore, setSelectedScore] = useState('');
  const [reasonForScore, setReasonForScore] = useState('');
  const [selectedScoreStep6, setSelectedScoreStep6] = useState('');
  const [feedbackStep6, setFeedbackStep6] = useState('');
  const [selectedMusicOption, setSelectedMusicOption] = useState({});
  const [selectedOptionStep8, setSelectedOptionStep8] = useState({ option: '', otherText: '' });
  const [howYouHeardAboutUs, setHowYouHeardAboutUs] = useState({ option: '', otherText: '' });
  const [selectedSatisfactionStep7, setSelectedSatisfactionStep7] = useState({ score: '', reason: '' });
  const [district, setDistrict] = useState('');
  const [promotionOptIn, setPromotionOptIn] = useState('');
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const totalSteps = 13; // Total de pasos es 13

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const handleSubmit = (formData) => {
    const allData = {
      nombreCompleto: formData?.nombre || 'No ingresado',
      telefono: formData?.telefono || 'No ingresado',
      dni: formData?.dni || 'No ingresado',
      servicios: selectedServices.length ? selectedServices : 'No seleccionado',
      frecuenciaVisita: selectedVisitFrequency || 'No seleccionado',
      ambiente: recommendationStep3 || 'No seleccionado',
      staff: selectedStaff || 'No seleccionado',
      puntaje: selectedScore || 'No seleccionado',
      motivoPuntaje: reasonForScore || 'No ingresado',
      puntajeLimpieza: selectedScoreStep6 || 'No seleccionado',
      feedbackLimpieza: feedbackStep6 || 'No ingresado',
      musica: selectedMusicOption.option || 'No seleccionado',
      recomendacionMusica: selectedMusicOption.otherText || 'No ingresado',
      mejoras: selectedOptionStep8.option || 'No seleccionado',
      otroMejoras: selectedOptionStep8.otherText || 'No ingresado',
      comoTeEnteraste: howYouHeardAboutUs.option || 'No seleccionado',
      otroEnterado: howYouHeardAboutUs.otherText || 'No ingresado',
      satisfaccion: selectedSatisfactionStep7.score || 'No seleccionado',
      motivoSatisfaccion: selectedSatisfactionStep7.reason || 'No ingresado',
      distrito: district || 'No seleccionado',
      recibirPromos: promotionOptIn || 'No seleccionado'
    };

    fetch('https://amapola.ryparquitectos.pe/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(allData),
    })
      .then((response) => {
        if (response.ok) {
          navigate('/thank-you');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Error al enviar la encuesta. Por favor, inténtalo de nuevo.',
          });
        }
      })
      .catch((error) => {
        console.error('Error al enviar la encuesta:', error);
        Swal.fire({
          icon: 'error',
          title: 'Problemas técnicos',
          text: 'Estamos teniendo problemas para procesar la solicitud. Por favor, intenta de nuevo más tarde.',
        });
      });
  };

  const nextStep = (isValid = true, selections) => {
    if (isValid && currentStep < totalSteps) {
      setIsEntering(false);

      setTimeout(() => {
        switch (currentStep) {
          case 1:
            setSelectedServices(selections || []);
            break;
          case 2:
            setSelectedVisitFrequency(selections?.option || '');
            break;
          case 3:
            setRecommendationStep3(selections || '');
            break;
          case 4:
            setSelectedStaff(selections || '');
            break;
          case 5:
            setSelectedScore(selections?.score || '');
            setReasonForScore(selections?.reason || '');
            break;
          case 6:
            setSelectedScoreStep6(selections?.selectedScore || '');
            setFeedbackStep6(selections?.feedback || '');
            break;
          case 7:
            setSelectedMusicOption(selections || {});
            break;
          case 8:
            setSelectedOptionStep8(selections || { option: '', otherText: '' });
            break;
          case 9:
            setHowYouHeardAboutUs(selections || { option: '', otherText: '' });
            break;
          case 10:
            setSelectedSatisfactionStep7(selections || { score: '', reason: '' });
            break;
          case 11:
            setDistrict(selections || '');
            break;
          case 12:
            setPromotionOptIn(selections || '');
            break;
          default:
            break;
        }
        setCurrentStep(currentStep + 1);
        setIsEntering(true);
        scrollToTop();
      }, 400);
    }
  };

  const prevStep = (selections) => {
    if (currentStep > 1) {
      setIsEntering(false);

      setTimeout(() => {
        switch (currentStep) {
          case 8:
            setSelectedOptionStep8(selections || { option: '', otherText: '' });
            break;
          case 9:
            setHowYouHeardAboutUs(selections || { option: '', otherText: '' });
            break;
          case 10:
            setSelectedSatisfactionStep7(selections || { score: '', reason: '' });
            break;
          case 11:
            setDistrict(selections || '');
            break;
          case 12:
            setPromotionOptIn(selections || '');
            break;
          default:
            break;
        }
        setCurrentStep(currentStep - 1);
        setIsEntering(true);
        scrollToTop();
      }, 400);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return <Step1 onNext={nextStep} savedSelections={selectedServices} currentStep={currentStep} />;
      case 2:
        return <Step2 onNext={nextStep} onBack={prevStep} savedSelection={{ option: selectedVisitFrequency }} currentStep={currentStep - 1} />; // Pregunta 1
      case 3:
        return <Step3 onNext={nextStep} onBack={prevStep} savedSelections={recommendationStep3} currentStep={currentStep - 1} />; // Pregunta 2
      case 4:
        return <Step4 onNext={nextStep} onBack={prevStep} savedSelection={selectedStaff} currentStep={currentStep - 1} />; // Pregunta 3
      case 5:
        return <Step5 onNext={nextStep} onBack={prevStep} savedScore={selectedScore} savedReason={reasonForScore} currentStep={currentStep - 1} />; // Pregunta 4
      case 6:
        return <Step6 onNext={nextStep} onBack={prevStep} savedScore={selectedScoreStep6} savedFeedback={feedbackStep6} currentStep={currentStep - 1} />; // Pregunta 5
      case 7:
        return <Step6Music onNext={nextStep} onBack={prevStep} savedSelection={selectedMusicOption} currentStep={currentStep - 1} />; // Pregunta 6
      case 8:
        return <Step8 onNext={nextStep} onBack={prevStep} savedSelection={selectedOptionStep8} currentStep={currentStep - 1} />; // Pregunta 7
      case 9:
        return <Step9 onNext={nextStep} onBack={prevStep} savedOption={howYouHeardAboutUs} currentStep={currentStep - 1} />; // Pregunta 8
      case 10:
        return <Step7 onNext={nextStep} onBack={prevStep} savedScore={selectedSatisfactionStep7.score} savedReason={selectedSatisfactionStep7.reason} currentStep={currentStep - 1} />; // Pregunta 9
      case 11:
        return <Step10 onNext={nextStep} onBack={prevStep} savedDistrict={district} currentStep={currentStep - 1} />; // Pregunta 10
      case 12:
        return <Step11 onNext={nextStep} onBack={prevStep} savedSelection={promotionOptIn} currentStep={currentStep - 1} />; // Pregunta 11
      case 13:
        return <FinalStep onBack={prevStep} onSubmit={handleSubmit} openModal={openModal} currentStep={currentStep} />;
      default:
        return <Step1 onNext={nextStep} savedSelections={selectedServices} currentStep={currentStep} />;
    }
  };

  const progressPercentage = ((currentStep - 1) / (totalSteps - 1)) * 100;

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <ProgressBarContainer>
            <ProgressBarFill width={progressPercentage} />
          </ProgressBarContainer>

          <FormContainer>
            <StepWrapper>
              <LogoImage src="/logo.webp" alt="Amapola Beauty Salon Logo" />
              <StepContent isEntering={isEntering}>{renderStep()}</StepContent>
            </StepWrapper>
          </FormContainer>

          {isModalOpen && (
            <ModalBackground>
              <ModalContent>
                <CloseButton onClick={closeModal}>Cerrar</CloseButton>
                <h4>Política de Privacidad y Tratamiento de Datos</h4>
                <p>
                  En <strong>Amapola Beauty Salón</strong>, nos comprometemos a
                  proteger tu privacidad y garantizar la confidencialidad de tus
                  datos personales. Los datos que proporciones a través de nuestro
                  formulario serán utilizados exclusivamente para mejorar nuestros
                  servicios, ofrecerte sorteos, promociones, descuentos y mejorar tu
                  experiencia con nosotros.
                </p>
                <p>
                  <strong>Finalidad del tratamiento:</strong> Utilizaremos tus datos
                  para:
                </p>
                <ul>
                  <li>Mejorar nuestros servicios y adaptarlos a tus necesidades.</li>
                  <li>Ofrecerte promociones, sorteos, ofertas y descuentos personalizados.</li>
                  <li>Contactarte para evaluar tu experiencia en nuestro salón y sugerir mejoras.</li>
                </ul>
                
                <p>
                  <strong>Responsabilidad y protección:</strong> Los datos serán
                  tratados exclusivamente por <strong>Amapola Beauty Salón</strong> y nos comprometemos a no compartirlos
                  con terceros sin tu consentimiento expreso. Garantizamos que tus
                  datos estarán protegidos de acuerdo con las normativas de
                  protección de datos aplicables.
                </p>
                <p>
                  Tienes derecho a acceder, rectificar o solicitar la eliminación de
                  tus datos personales en cualquier momento. Para ejercer estos
                  derechos, puedes contactarnos a través de nuestros canales
                  oficiales.
                </p>
                <p>Fecha de última actualización: 22 de septiembre de 2024</p>
              </ModalContent>
            </ModalBackground>
          )}
        </>
      )}
    </>
  );
};

export default MultiStepForm;